import React, { useState } from "react";
import cn from "classnames";
import { navigate } from "gatsby";

import PageLayout from "../components/page-layout";
import Progress from "../components/progress";

import ukraineImage from "../images/ukraine.png";

import * as style from "./steps.module.css";
import { getFormDataObject } from "../utils/form-data";
import FileUploadInput from "../components/file-input";

const Step2 = ({ location }) => {
  const [submitting, setSubmitting] = useState(false);

  // console.log("STEP 2:", location);

  return (
    <PageLayout
      title="Call A Colleague - Step 2"
      description="Peer to Peer Career Mentoring for professionals forced to flee"
      image={ukraineImage}
    >
      <Progress step={2} stepCount={2}>
        <section id="step2" className={style.bookSection}>
          <h2>Your Contact Data</h2>
          <form
            className={cn(style.form, { [style.busy]: submitting })}
            onSubmit={async (event) => {
              event.preventDefault();
              setSubmitting(true);

              const data = {
                ...location.state.step1,
                ...getFormDataObject(event.target),
              };

              const response = await fetch(
                `${process.env.GATSBY_FUNCTIONS_URL}/calls`,
                {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const responseData = await response.json();

              setSubmitting(false);

              if (!responseData.ok) {
                alert(`Submission failed: ${responseData.message}`);
                throw new Error(`Submission failed: ${responseData.message}`);
              }

              navigate("/submission-success");
            }}
          >
            <div>
              <label htmlFor="form-name">Your full name</label>
              <input
                id="form-name"
                name="name"
                placeholder="Mykhailo Kotsiubynsky"
                required
              />
            </div>
            <div>
              <label htmlFor="form-email">Your email</label>
              <input
                id="form-email"
                name="email"
                placeholder="myemail@provider.com"
                required
              />
            </div>
            <div>
              <label htmlFor="form-phone">Your phone number</label>
              <input
                id="form-phone"
                name="phone"
                placeholder="+380123456789"
                required
              />
            </div>

            <FileUploadInput
              preLabel="Upload your resume (it greatly helps the mentor to help you)"
              defaultLabel="Add file"
            />

            <div className={style.spinnerContainer}>
              <input type="submit" value="Submit" disabled={submitting} />
            </div>
          </form>
        </section>
      </Progress>
    </PageLayout>
  );
};

export default Step2;
