import React from "react";

import * as style from "./file-input.module.css";

const removeFileItem = async (fileId) =>
  fetch(`${process.env.GATSBY_FUNCTIONS_URL}/file/${fileId}`, {
    method: "DELETE",
  });

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(`${process.env.GATSBY_FUNCTIONS_URL}/file`, {
    method: "POST",
    body: formData,
  });

  return response.json();
};

export default function FileUploadInput({ preLabel, defaultLabel }) {
  const [files, setFiles] = React.useState([]);

  return (
    <div className="form-element">
      {preLabel && <label>{preLabel}</label>}
      {files.map((file, index) => {
        const removeFile = async () => {
          files.splice(index, 1);
          setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
          await removeFileItem(file.id);
        };

        return (
          <div key={index} id={"item" + index} className={style.fileItem}>
            {file.name}
            <i
              className={file.finished ? style.uploaded : style.processing}
              onClick={removeFile}
              onKeyDown={removeFile}
              role="button"
              tabIndex={0}
              aria-label="Remove file"
            />
            <input type="hidden" name={"file" + index} value={file.id} />
          </div>
        );
      })}
      <input
        type="file"
        id="upload-resume-btn"
        className={style.fileInput}
        onChange={async (event) => {
          if (event.target.files.length === 0) {
            return;
          }

          const file = event.target.files[0];
          file.id = "";
          event.target.value = "";

          const newFiles = [...files, file];
          setFiles(newFiles);

          const response = await uploadFile(file);

          file.id = response.id;
          file.finished = true;
          setFiles([...newFiles]);
        }}
      />
      <label htmlFor="upload-resume-btn" className={style.fileInputLabel}>
        <span>{defaultLabel || "Choose file..."}</span>
      </label>
    </div>
  );
}
